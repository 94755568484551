<template>
    <div>
        跳转中，请稍后...
    </div>
</template>
<script>
export default {
  name: 'href',
  props:{
 
  },
  data(){
        return {
        }
    },
    mounted(){
        let url = this.$store.state.payUrl
        if(url && url != ''){
            window.location.href = url
        }else{
            // this.$router.push({path:'/order',query:{active:0}})
            this.$router.push({path: '/index'});
        }
    },
    methods:{
    }

}
</script>
<style lang="less" scoped>

</style>
